import React, { useState, useEffect, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import { PermittedPotentialViolation } from './PermittedPotentialViolation';
import { IViolation } from '../../types/interfaces/iViolation';
import { getPotentialViolations } from '../../services/api/potentialViolationsService';
import { PotentialViolationHeader } from './PotentialViolationsHeader';
import { Potential } from './Potential';
import { getPermittedPotentialViolationsCount } from '../../services/api/permittedPotentialViolationsCountService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import CONSTANTS from '../../shared/constants';
import { useAuth } from 'react-oidc-context';
import { IPermittedVehicle } from '../../types/interfaces/iPermittedVehicle';

export const PermittedPotentialViolationEvents = () => {
  const [potentialViolations, setViolations]: [IViolation[], Function] = useState([]);
  const [loading, setLoading]: [boolean, Function] = useState(true);
  const [pageNum, setPageNum]: [number, Function] = useState(1);
  const [lastElement, setLastElement] = useState<HTMLDivElement | null>(null);
  const [totalPages, setTotalPages]: [number, Function] = useState(0);
  const [uploadTime, setUploadTime]: [number | null, Function] = useState(null);
  const [totalCount, setTotalCount]: [number, Function] = useState(0);
  const [totalPermittedVehiclesCount, setTotalPermittedVehiclesCount]: [number, Function] = useState(0);

  const tenantId = localStorage.getItem(CONSTANTS.tenantKey) as string;
  const auth = useAuth();

  const observer = useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting) {
        setPageNum((no: number) => no + 1);
      }
    })
  );

  const findUploadTimeAndSet = (data: IViolation[]) => {
    if (!data?.length) return;
    if (pageNum === 1) {
      const potential1 = data[0].uploadTime + 1;
      const potential2 = data[1].uploadTime + 1;
      potential1 >= potential2 ? setUploadTime(potential1) : setUploadTime(potential2);
    }
  };

  const getEvents = async () => {
    setLoading(true);
    try {
      const tokenType = auth.user?.token_type;
      const accessToken = auth.user?.access_token;
      const authorizationHeader = `${tokenType} ${accessToken}`;
      const { status, body } = await getPotentialViolations(tenantId, pageNum, authorizationHeader, uploadTime);
      const { status: permittedVehiclesCountStatus, body: permittedVehiclesCount } = await getPermittedPotentialViolationsCount(
        tenantId,
        authorizationHeader
      );

      if (status !== 200 || permittedVehiclesCountStatus !== 200) {
        if (body.error.includes('Unknown database')) {
          alert(`Tenant ${tenantId} not found!`);
        }
        setLoading(false);
        return;
      }
      const ids = new Set(potentialViolations.map((d: IViolation) => d.id));
      const data = [...potentialViolations, ...body.data.data.filter((d: IViolation) => !ids.has(d.id))];
      setTotalPages(body.data.meta.pageCount);
      setTotalCount(body.data.meta.totalCount - permittedVehiclesCount.data);
      setTotalPermittedVehiclesCount(permittedVehiclesCount.data);
      setViolations(data);
      findUploadTimeAndSet(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (pageNum <= totalPages || totalPages === 0) {
      getEvents();
    }
  }, [pageNum]);

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);
  if (!tenantId) {
    return <Navigate to="/unauthorized" />;
  }

  const isPotentialOrPermitted = (potentialViolation: IViolation) => {
    const permittedVehicles = potentialViolation.permittedVehicles && potentialViolation.permittedVehicles.length > 0;
    const isPermittedVehiclesEnabled = potentialViolation.permittedVehicles?.some((permittedVehicle: IPermittedVehicle) => permittedVehicle.enabled);

    if (permittedVehicles && isPermittedVehiclesEnabled) {
      return <PermittedPotentialViolation violation={potentialViolation} key={potentialViolation.id} />;
    }
    return <Potential violation={potentialViolation} key={potentialViolation.id} />;
  };

  const setDefaultText = (isLoading: boolean) => {
    return !isLoading ? (
      <p className="no-detections-text">
        Couldn't find any observation or permitted vehicle by now, please check the configuration or try again later
      </p>
    ) : null;
  };

  return (
    <>
      <PotentialViolationHeader violationsCount={totalCount} permittedVehiclesCount={totalPermittedVehiclesCount} />
      <div className="list">
        {potentialViolations.length
          ? potentialViolations.map((potentialViolation: IViolation, i: number) => {
              return i === potentialViolations.length - 1 && !loading && pageNum <= totalPages ? (
                <div key={`${potentialViolation.id}-${i}`} ref={setLastElement}>
                  {isPotentialOrPermitted(potentialViolation)}
                </div>
              ) : (
                isPotentialOrPermitted(potentialViolation)
              );
            })
          : setDefaultText(loading)}

        {loading ? (
          <div style={{ padding: '100px', textAlign: 'center' }}>
            <FontAwesomeIcon icon={faSpinner} spin size="4x" />
          </div>
        ) : null}
      </div>
    </>
  );
};
