import React from 'react';
import { HeaderProps } from '../../types/props/headerProps';

export const PotentialViolationHeader = ({ violationsCount, permittedVehiclesCount }: HeaderProps) => (
  <nav className="nav-extended grey darken-4">
    <div className="nav-content">
      <span className="nav-title">
        Observations ({violationsCount}) Permitted vehicles({permittedVehiclesCount})
      </span>
    </div>
  </nav>
);
