import React, { useEffect, useState } from "react";
import { AuthProvider } from "react-oidc-context";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

function AppLoader() {
  const [settings, setData] = useState([]);
  const getSettings = () => {
    fetch('/assets/appsettings.json'
      , {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (settings) {
        setData(settings)
      });
  }
  useEffect(() => {
    getSettings()
  }, []);
  return (
    <>
      {settings.sso ? (
        <AuthProvider {...settings.sso} >
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AuthProvider >
      )
        : "Loading..."}
    </>
  )
}
export default AppLoader;
