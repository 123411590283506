export const buildQueryString = (queryParams: {
  createdDateTime: number;
  licensePlate: string;
  busNumber: string;
  startCaptureDateTime: number;
  endCaptureDateTime: number;
  detectionType: string;
  approved: boolean;
}) => {
  const wheres = [];
  const { createdDateTime, licensePlate, busNumber, startCaptureDateTime, endCaptureDateTime, detectionType, approved } = queryParams;
  if (createdDateTime) wheres.push(`confirmedViolation.createdDateTime:lte:${createdDateTime}`);
  if (licensePlate) wheres.push(`potentialViolation.licensePlateNumber:like:${licensePlate}`);
  if (busNumber) wheres.push(`potentialViolation.busId:like:${busNumber}`);
  if (startCaptureDateTime) wheres.push(`potentialViolation.captureDateTimestamp:gte:${startCaptureDateTime}`);
  if (endCaptureDateTime) wheres.push(`potentialViolation.captureDateTimestamp:lte:${endCaptureDateTime}`);
  if (detectionType === 'Potential Violations') wheres.push(`confirmedViolation.permittedVehicle:eq:false`);
  if (detectionType === 'Permitted Vehicles') wheres.push(`confirmedViolation.permittedVehicle:eq:true`);
  if (typeof approved === 'boolean') wheres.push(`confirmedViolation.approved:eq:${approved}`);

  return wheres.length ? 'query=' + wheres.join() : '';
};
