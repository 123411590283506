import React, { useState } from 'react';
import { IConfirmedViolation } from '../../types/interfaces/iConfirmedViolation';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { approveConfirmedViolation } from '../../services/api/approveConfirmedViolation';

export const ApproveConfirmedViolation = ({
  confirmedViolation,
  tenantId,
  authorizationHeader,
}: {
  confirmedViolation: IConfirmedViolation;
  tenantId: string;
  authorizationHeader: string;
}) => {
  const [loadingApprove, setLoadingApprove] = useState(false);

  const approve = async () => {
    try {
      setLoadingApprove(true);
      const { status } = await approveConfirmedViolation(tenantId, confirmedViolation.id, authorizationHeader);

      if (status !== 200) {
        setLoadingApprove(false);
        return;
      }
      confirmedViolation.approved = true;
      setLoadingApprove(false);
    } catch (error) {
      setLoadingApprove(false);
      return;
    }
  };
  return (
    <>
      {loadingApprove ? (
        <FontAwesomeIcon icon={faSpinner} spin size="4x" />
      ) : confirmedViolation.approved ? (
        <h6>Approved</h6>
      ) : (
        <a className="waves-effect waves-light btn grey darken-4 btn-small" onClick={() => approve()}>
          Approve
        </a>
      )}
    </>
  );
};
