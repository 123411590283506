import React from 'react';
import { getDateTimeString } from '../../services/utils/dateTime';
import { IViolation } from '../../types/interfaces/iViolation';
import { ImageView } from '../ImageView/Main';

export const Potential = ({ violation }: { violation: IViolation }) => {
  return (
    <div className="row">
      <div className="col s12 m12">
        <div className="card grey lighten-3">
          <div className="card-content black-text">
            <div className="row card-content" style={{ padding: '5px 24px' }}>
              <span className="card-title">
                <strong>Observation</strong>
              </span>
              <div className="col m4">
                <span className="card-title">
                  <strong>Plate:</strong> {violation.licensePlateNumber}
                </span>
                <p>
                  <strong>State of Origin:</strong> {violation.licensePlateState.toUpperCase()}{' '}
                </p>
                <p>
                  <strong>Observation ID:</strong> {violation.id}{' '}
                </p>
                <p>
                  <strong>Device Id:</strong> {violation.deviceId}{' '}
                </p>
                <p>
                  <strong>Capture Time:</strong> {getDateTimeString(violation.captureDateTimestamp)}{' '}
                </p>
                <p>
                  <strong>Upload Time:</strong> {getDateTimeString(violation.uploadTime)}{' '}
                </p>
                <p>
                  <strong>Enforcement Zone Code:</strong> {violation.geofenceCode}{' '}
                </p>
                <p>
                  <strong>Location:</strong> {violation.geofenceAddress}{' '}
                </p>
                {violation.zone ? (
                  <p>
                    <strong>Infraction Zone:</strong> {violation.zone}{' '}
                  </p>
                ) : null}
                {violation.type ? (
                  <p>
                    <strong>Infraction Type:</strong> {violation.type}{' '}
                  </p>
                ) : null}
                <p>
                  <strong>Latitude:</strong> {violation.gpsLatitude}{' '}
                </p>
                <p>
                  <strong>Longitude:</strong> {violation.gpsLongitude}{' '}
                </p>
                <p>
                  <strong>Bus Number:</strong> {violation.busId}{' '}
                </p>
                <p>
                  <strong>Bus Route:</strong> {violation.geofenceBlockRoute}{' '}
                </p>
                <p>
                  <strong>Route Direction:</strong> {violation.geofenceRouteDirection}{' '}
                </p>
              </div>
              <div className="col m4">
                <ImageView packageId={violation.frontFacingImagePackageId}></ImageView>
              </div>
              <div className="col m4">
                <ImageView packageId={violation.licensePlateImagePackageId}></ImageView>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
