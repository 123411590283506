export const approveConfirmedViolation = async (tenantId: string, confirmedViolationId: string, authorizationHeader: string) => {
  try {
    const host = window.location.origin;
    const response = await fetch(`${host}/api/confirmedViolations/${confirmedViolationId}/approve`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Tenant-Id': tenantId,
        'Authorization': authorizationHeader
      },
    });

    return { status: response.status };
  } catch (err) {
    throw new Error(`Error trying to approve confirmed Violation ${err}`);
  }
};
