import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getPackageViewUrl } from '../../services/api/packageViewUrlService';
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import CONSTANTS from '../../shared/constants';
import { useAuth } from 'react-oidc-context';

export const ImageView = ({ packageId }: { packageId: string }) => {
  const [loading, setLoading]: [boolean, Function] = useState(true);
  const [imagePath, setImagePath]: [string, Function] = useState(process.env.REACT_APP_IMG_PROCESS || '');

  const tenantId = localStorage.getItem(CONSTANTS.tenantKey);
  const auth = useAuth();
  useEffect(() => {
    getFilePath();
  }, []);

  const getFilePath = async (): Promise<void> => {
    if (!tenantId || !packageId) {
      setLoading(false);
      return;
    }
    try {
      const tokenType = auth.user?.token_type;
      const accessToken = auth.user?.access_token;
      const authorizationHeader = `${tokenType} ${accessToken}`;
      const { status, data } = await getPackageViewUrl(tenantId, packageId, authorizationHeader);
      setLoading(false);
      if (status !== 200) return;
      if (data) {
        setImagePath(data);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    loading ? (
      <div style={{ padding: '100px', textAlign: 'center' }}>
        <FontAwesomeIcon icon={faSpinner} spin size="4x" />
      </div>
    ) : (
      <>
        <img style={{ width: '100%' }} src={imagePath} alt={packageId} />
      </>
    )
  );
};
