export const getPermittedPotentialViolationsCount = async (tenantId: string, authorizationHeader: string) => {
  try {
    const host = window.location.origin;
    const response = await fetch(`${host}/api/potentialViolations/permittedVehicles/count`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Tenant-Id': tenantId,
        Authorization: authorizationHeader,
      },
    });
    const body = await response.json();

    return { status: response.status, body };
  } catch (err) {
    throw new Error(`Error trying to get potential violations ${err}`);
  }
};
