export const getPackageViewUrl = async (tenantId: string, packageId: string, authorizationHeader: string) => {
  try {
    const host = window.location.origin;
    const response = await fetch(`${host}/api/package/${packageId}/view`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Tenant-Id': tenantId,
        'Authorization': authorizationHeader
      },
    });

    return await response.json();
  } catch (err) {
    throw new Error(`Error trying to get package view url. Error: ${err}`);
  }
};
