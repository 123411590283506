import React from 'react';
import ReactDOM from 'react-dom';
import 'materialize-css';
import 'materialize-css/dist/css/materialize.min.css';
import AppLoader from './app-loader';

ReactDOM.render(
  <AppLoader />,
  document.getElementById('root')
)
