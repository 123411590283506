import React from 'react';
import { SearchProps } from '../../types/props/searchProps';
import './Search.css';

export const Search = ({
  onLicensePlateChange,
  licensePlate,
  onBusNumberChange,
  busNumber,
  onStartCaptureDateTimeChange,
  startCaptureDateTime,
  onEndCaptureDateTimeChange,
  endCaptureDateTime,
  onDetectionTypeChange,
  detectionType,
  clearFilters,
  searchDetections,
}: SearchProps) => {
  return (
    <form className="filters-form row col s12 grey darken-4">
      <div className="input-field col s2">
        <span className="white-text">License Plate: </span>
        <input
          className="filter-field"
          type="text"
          autoComplete="off"
          name="licensePlate"
          onChange={(event) => onLicensePlateChange(event.target.value)}
          value={licensePlate}
        />
      </div>
      <div className="input-field col s2">
        <span className="white-text">Bus Number: </span>
        <input
          className="filter-field"
          type="text"
          autoComplete="off"
          name="busNumber"
          onChange={(event) => onBusNumberChange(event.target.value)}
          value={busNumber}
        />
      </div>
      <div className="input-field col s2">
        <span className="white-text">From: </span>
        <input
          className="filter-field"
          id="captureDateFrom"
          type="datetime-local"
          onChange={(event) => onStartCaptureDateTimeChange(event.target.value)}
          value={startCaptureDateTime}
        />
      </div>
      <div className="input-field col s2">
        <span className="white-text">To: </span>
        <input
          className="filter-field"
          id="captureDateTo"
          type="datetime-local"
          onChange={(event) => onEndCaptureDateTimeChange(event.target.value)}
          value={endCaptureDateTime}
        />
      </div>
      <div className="input-field col s2">
        <span className="white-text">Detection Type: </span>
        <select className="detection-type-select" onChange={(event) => onDetectionTypeChange(event.target.value)} value={detectionType}>
          <option value="">All</option>
          <option value="Potential Violations">Potential Violations</option>
          <option value="Permitted Vehicles">Permitted Vehicles</option>
        </select>
      </div>
      <div className="col s2">
        <a className="grey darken-4 btn-large" onClick={() => searchDetections()} style={{ position: 'relative', top: '29px' }}>
          <i className="material-icons">search</i>
        </a>
        <a className="grey darken-4 btn-large" onClick={() => clearFilters()} style={{ position: 'relative', top: '29px' }}>
          <i className="material-icons">restore</i>
        </a>
      </div>
    </form>
  );
};
