import StringUtils from "../../shared/string-utils";

export default class AuthorizationUtils {
  static getFirstTenantId(tenants: string | string[]): string | null {
    if (!tenants) {
      return null;
    }
    if (typeof tenants === 'string') {
      return !StringUtils.isNullOrWhitespace(tenants) ? tenants : null;
    }
    if (Array.isArray(tenants) && tenants.length > 0) {
      return tenants[0];
    }
    // Unknown content
    return null;
  }
}
