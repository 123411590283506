import React from 'react';
import { useAuth } from 'react-oidc-context';
import { Routes, Route } from 'react-router-dom';
import { ConfirmedViolationEvents } from './components/ConfirmedViolations/Main';
import { PermittedPotentialViolationEvents } from './components/PotentialViolations/Main';
import SigninCallback from './components/Authentication/signin-callback';
import './App.css';
import Unauthorized from './components/Authentication/Unauthorized';
import Logout from './components/Authentication/logout';

function App() {
  const auth = useAuth();
  switch (auth.activeNavigator) {
    case 'signinSilent':
      return <div>Signing you in...</div>;
    case 'signoutRedirect':
      return <div>Signing you out...</div>;
  }
  if (auth.isLoading) {
    return <div>Loading...</div>;
  }
  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }
  if (!auth.isAuthenticated) {
    auth.signinRedirect();
    return <div>Signing you in...</div>;
  }

  return (
    <div className="App">
      <Logout />
      <Routes>
        <Route path="/" element={<ConfirmedViolationEvents approved={true} />} />
        <Route path="/observations" element={<PermittedPotentialViolationEvents />} />
        <Route path="/potential-violations" element={<ConfirmedViolationEvents approved={false} />} />
        <Route path="/signin-callback" element={<SigninCallback />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
      </Routes>
    </div>
  );
}

export default App;
