import { buildQueryString } from './buildQueryString';

export const getPermittedConfirmedViolationsCount = async (
  params: {
    page: number;
    createdDateTime: number;
    licensePlate: string;
    busNumber: string;
    startCaptureDateTime: number;
    endCaptureDateTime: number;
    detectionType: string;
    approved: boolean;
  },
  headersParams: { tenantId: string; authorizationHeader: string }
) => {
  const { createdDateTime, licensePlate, busNumber, startCaptureDateTime, endCaptureDateTime, detectionType, approved } = params;
  const { tenantId, authorizationHeader } = headersParams;
  const query = buildQueryString({ createdDateTime, licensePlate, busNumber, startCaptureDateTime, endCaptureDateTime, detectionType, approved });
  try {
    const host = window.location.origin;
    const response = await fetch(`${host}/api/confirmedViolations/permittedVehicles/count${query ? '?' + query : ''}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Tenant-Id': tenantId,
        Authorization: authorizationHeader,
      },
    });
    const body = await response.json();

    return { status: response.status, body };
  } catch (err) {
    throw new Error(`Error trying to get potential violations ${err}`);
  }
};
