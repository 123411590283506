import React, { useState, useEffect, useRef } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { ConfirmedViolation } from './ConfirmedViolation';
import { ConfirmedViolationHeader } from './ConfirmedViolationsHeader';
import { IConfirmedViolation } from '../../types/interfaces/iConfirmedViolation';
import { getConfirmedViolations } from '../../services/api/confirmedViolationsService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { getPermittedConfirmedViolationsCount } from '../../services/api/permittedConfirmedViolationsCountService';
import CONSTANTS from '../../shared/constants';
import { useAuth } from 'react-oidc-context';
import { Search } from '../Search/Main';

export const ConfirmedViolationEvents = ({ approved }: { approved: boolean }) => {
  const [confirmedViolations, setViolations]: [IConfirmedViolation[], Function] = useState([]);
  const [loading, setLoading]: [boolean, Function] = useState(true);
  const [pageNum, setPageNum]: [number, Function] = useState(1);
  const [lastRenderedElement, setLastRenderedElement] = useState<HTMLDivElement | null>(null);
  const [totalPages, setTotalPages]: [number, Function] = useState(0);
  const [totalCount, setTotalCount]: [number, Function] = useState(0);
  const [createdDateTime, setCreatedDateTime]: [number, Function] = useState(0);
  const [totalPermittedVehiclesCount, setTotalPermittedVehiclesCount]: [number, Function] = useState(0);
  const [licensePlate, setLicensePlate]: [string, Function] = useState('');
  const [busNumber, setBusNumber]: [string, Function] = useState('');
  const [startCaptureDateTime, setStartCaptureDateTime]: [string, Function] = useState('');
  const [endCaptureDateTime, setEndCaptureDateTime]: [string, Function] = useState('');
  const [detectionType, setDetectionType]: [string, Function] = useState('');
  const [search, setSearch]: [boolean, Function] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const validLicensePlateRegex = /^[a-zA-Z0-9]*$/;
  const validBusNumberRegex = /^[a-zA-Z0-9-]*$/;
  const milliSecondsInAMinute = 59999;

  const tenantId = localStorage.getItem(CONSTANTS.tenantKey) as string;
  const auth = useAuth();

  const observer = useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting) {
        setPageNum((no: number) => no + 1);
      }
    })
  );

  const setCreatedDateTimeValue = (data: IConfirmedViolation[]) => {
    if (!data?.length) return;
    if (pageNum === 1) {
      setCreatedDateTime(data[0].createdDateTime + 1);
    }
  };

  const setLicensePlateValue = (licensePlateValue: string) => {
    if (!licensePlateValue) {
      searchParams.delete('licensePlate');
      setSearchParams(searchParams);
      setLicensePlate(licensePlateValue);
      return;
    }
    const isValidInput = validLicensePlateRegex.test(licensePlateValue);
    if (isValidInput) {
      searchParams.set('licensePlate', licensePlateValue);
      setSearchParams(searchParams);
      setLicensePlate(licensePlateValue);
    }
  };

  const setBusNumberValue = (busNumberValue: string) => {
    if (!busNumberValue) {
      searchParams.delete('busNumber');
      setSearchParams(searchParams);
      setBusNumber(busNumberValue);
      return;
    }
    const isValidInput = validBusNumberRegex.test(busNumberValue);
    if (isValidInput) {
      searchParams.set('busNumber', busNumberValue);
      setSearchParams(searchParams);
      setBusNumber(busNumberValue);
    }
  };

  const setStartCaptureDateTimeValue = (startCaptureDateTimeValue: string) => {
    if (!startCaptureDateTimeValue) searchParams.delete('startCaptureDateTime');
    else searchParams.set('startCaptureDateTime', startCaptureDateTimeValue);
    setSearchParams(searchParams);
    setStartCaptureDateTime(startCaptureDateTimeValue);
  };

  const setEndCaptureDateTimeValue = (endCaptureDateTimeValue: string) => {
    if (!endCaptureDateTimeValue) searchParams.delete('endCaptureDateTime');
    else searchParams.set('endCaptureDateTime', endCaptureDateTimeValue);
    setSearchParams(searchParams);
    setEndCaptureDateTime(endCaptureDateTimeValue);
  };

  const setDetectionTypeValue = (detectionTypeValue: string) => {
    if (!detectionTypeValue) searchParams.delete('detectionType');
    else searchParams.set('detectionType', detectionTypeValue);
    setSearchParams(searchParams);
    setDetectionType(detectionTypeValue);
  };

  const resetPageInfo = () => {
    setPageNum(1);
    setTotalPages(0);
    setTotalCount(0);
    setTotalPermittedVehiclesCount(0);
    setViolations([]);
    setCreatedDateTime(0);
  };

  const searchDetections = async () => {
    resetPageInfo();
    setSearch(true);
  };

  const clearFilters = () => {
    setLicensePlateValue('');
    setBusNumberValue('');
    setStartCaptureDateTimeValue('');
    setEndCaptureDateTimeValue('');
    setDetectionTypeValue('');
    resetPageInfo();
    setSearch(true);
  };

  const setTotalCounts = ({
    total,
    totalPermittedVehicles,
    detectionType,
  }: {
    total: number;
    totalPermittedVehicles: number;
    detectionType: string;
  }) => {
    if (detectionType === 'Potential Violations') {
      setTotalCount(total - totalPermittedVehicles);
      setTotalPermittedVehiclesCount(0);
    }
    if (detectionType === 'Permitted Vehicles') {
      setTotalCount(0);
      setTotalPermittedVehiclesCount(totalPermittedVehicles);
    } else {
      setTotalCount(total - totalPermittedVehicles);
      setTotalPermittedVehiclesCount(totalPermittedVehicles);
    }
  };

  const getTimeRanges = (startTime: string, endTime: string) => {
    const startDateTime = startTime ? new Date(startTime).getTime() : 0;
    const endDateTime = endTime ? new Date(endTime).getTime() + milliSecondsInAMinute : 0;
    return { startDateTime, endDateTime };
  };

  const getFilterValues = () => {
    const licensePlate = searchParams.get('licensePlate');
    if (licensePlate) setLicensePlateValue(licensePlate);
    const busNumber = searchParams.get('busNumber');
    if (busNumber) setBusNumberValue(busNumber);
    const startCaptureDateTime = searchParams.get('startCaptureDateTime');
    if (startCaptureDateTime) setStartCaptureDateTimeValue(startCaptureDateTime);
    const endCaptureDateTime = searchParams.get('endCaptureDateTime');
    if (endCaptureDateTime) setEndCaptureDateTimeValue(endCaptureDateTime);
    const detectionType = searchParams.get('detectionType');
    if (detectionType) setDetectionTypeValue(detectionType);

    return { licensePlate, busNumber, startCaptureDateTime, endCaptureDateTime, detectionType };
  };

  const getEvents = async () => {
    setLoading(true);
    try {
      const tokenType = auth.user?.token_type;
      const accessToken = auth.user?.access_token;
      const authorizationHeader = `${tokenType} ${accessToken}`;
      const filterValues = getFilterValues();
      const { startDateTime, endDateTime } = getTimeRanges(filterValues.startCaptureDateTime || '', filterValues.endCaptureDateTime || '');
      const filterParams = {
        page: pageNum,
        createdDateTime,
        licensePlate: filterValues.licensePlate || '',
        busNumber: filterValues.busNumber || '',
        startCaptureDateTime: startDateTime,
        endCaptureDateTime: endDateTime,
        detectionType: filterValues.detectionType || '',
        approved,
      };
      const headersParams = { tenantId, authorizationHeader };
      const { status, body } = await getConfirmedViolations(filterParams, headersParams);
      const { status: permittedVehiclesCountStatus, body: permittedVehiclesCount } = await getPermittedConfirmedViolationsCount(
        filterParams,
        headersParams
      );
      if (status !== 200 || permittedVehiclesCountStatus !== 200) {
        if (body.error.includes('Unknown database')) {
          alert(`Tenant ${tenantId} not found!`);
        }
        setLoading(false);
        return;
      }
      const ids = new Set(confirmedViolations.map((d: IConfirmedViolation) => d.id));
      const data = [...confirmedViolations, ...body.data.data.filter((d: IConfirmedViolation) => !ids.has(d.id))];
      setTotalPages(body.data.meta.pageCount);
      setTotalCounts({ total: body.data.meta.totalCount, totalPermittedVehicles: permittedVehiclesCount.data, detectionType });
      setViolations(data);
      setCreatedDateTimeValue(data);
    } finally {
      setLoading(false);
      setSearch(false);
    }
  };

  useEffect(() => {
    if (search) getEvents();
  }, [search]);

  useEffect(() => {
    if ((pageNum <= totalPages || totalPages === 0) && !search) {
      getEvents();
    }
  }, [pageNum]);

  useEffect(() => {
    const currentElement = lastRenderedElement;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastRenderedElement]);

  const setDefaultText = (isLoading: boolean) => {
    return !isLoading ? (
      <p className="no-detections-text"> Couldn't find any potential violation by now, please check the configuration or try again later</p>
    ) : null;
  };
  if (!tenantId) {
    return <Navigate to="/unauthorized" />;
  }
  const tokenType = auth.user?.token_type;
  const accessToken = auth.user?.access_token;
  const authorizationHeader = `${tokenType} ${accessToken}`;

  return (
    <>
      <ConfirmedViolationHeader violationsCount={totalCount} permittedVehiclesCount={totalPermittedVehiclesCount}></ConfirmedViolationHeader>
      <Search
        onLicensePlateChange={setLicensePlateValue}
        licensePlate={licensePlate}
        onBusNumberChange={setBusNumberValue}
        busNumber={busNumber}
        onStartCaptureDateTimeChange={setStartCaptureDateTimeValue}
        startCaptureDateTime={startCaptureDateTime}
        onEndCaptureDateTimeChange={setEndCaptureDateTimeValue}
        endCaptureDateTime={endCaptureDateTime}
        onDetectionTypeChange={setDetectionTypeValue}
        detectionType={detectionType}
        searchDetections={searchDetections}
        clearFilters={clearFilters}
      />
      <div className="list">
        {confirmedViolations.length && tenantId
          ? confirmedViolations.map((confirmedViolation: IConfirmedViolation, i: number) => {
              return i === confirmedViolations.length - 1 && !loading && pageNum <= totalPages ? (
                <div key={`${confirmedViolation.id}-${i}`} ref={setLastRenderedElement}>
                  <ConfirmedViolation
                    tenantId={tenantId}
                    event={confirmedViolation}
                    key={confirmedViolation.id}
                    authorizationHeader={authorizationHeader}
                  />
                </div>
              ) : (
                <ConfirmedViolation
                  tenantId={tenantId}
                  event={confirmedViolation}
                  key={confirmedViolation.id}
                  authorizationHeader={authorizationHeader}
                />
              );
            })
          : setDefaultText(loading)}

        {loading ? (
          <div style={{ padding: '100px', textAlign: 'center' }}>
            <FontAwesomeIcon icon={faSpinner} spin size="4x" />
          </div>
        ) : null}
      </div>
    </>
  );
};
