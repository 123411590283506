import React from "react";
import { useAuth } from "react-oidc-context";
import { Navigate } from 'react-router-dom';
import CONSTANTS from "../../shared/constants";
import AuthorizationUtils from "./authorization-utils";

function SigninCallback() {
  window.history.replaceState(
    {},
    document.title,
    window.location.pathname
  )
  const auth = useAuth();
  // Array when multiple tenants, string when single
  const tenants = auth.user?.profile?.tenant_id as string[] | string;
  const firstTenant = AuthorizationUtils.getFirstTenantId(tenants);
  if (!firstTenant) {
    return <Navigate to="/unauthorized" />;
  }
  localStorage.setItem(CONSTANTS.tenantKey, firstTenant)
  return (<Navigate to="/" />);
}

export default SigninCallback;
