import React from 'react';
import { useAuth } from 'react-oidc-context';

function Logout() {
  const auth = useAuth();
  return (
    <nav className="nav-extended">
      <div className="user-panel nav-content right">
        <a className="waves-effect grey darken-4 lighten-2 btn-small" onClick={() => void auth.signoutRedirect()}>
          Log out
        </a>
      </div>
    </nav>
  );
}

export default Logout;
