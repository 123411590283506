import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFilePdf, faFileVideo, faFileCode } from '@fortawesome/free-regular-svg-icons';
import { IViolation } from '../../types/interfaces/iViolation';
import { IConfirmedViolation } from '../../types/interfaces/iConfirmedViolation';
import { PotentialViolation } from './PotentialViolation';
import { getPackageViewUrl } from '../../services/api/packageViewUrlService';
import { ImageView } from '../ImageView/Main';
import { ApproveConfirmedViolation } from './ApproveConfirmedViolation';

library.add(faFilePdf, faFileVideo, faFileCode);

const GetViolations = ({ violations }: { violations: IViolation[] }) => {
  if (violations.length === 1) {
    return (
      <>
        <div className="row card-content black-text">
          <PotentialViolation violation={violations[0]} />
          <div className="col m4">
            <ImageView packageId={violations[0].frontFacingImagePackageId}></ImageView>
          </div>
          <div className="col m4">
            <ImageView packageId={violations[0].licensePlateImagePackageId}></ImageView>
          </div>
        </div>
      </>
    );
  }
  if (violations.length > 1) {
    return (
      <>
        <div className="row card-content black-text">
          <div className="col m6">
            <PotentialViolation violation={violations[0]} />
            <div className="col m6">
              <ImageView packageId={violations[0].frontFacingImagePackageId}></ImageView>
              <ImageView packageId={violations[0].licensePlateImagePackageId}></ImageView>
            </div>
          </div>
          <div className="col m6">
            <PotentialViolation violation={violations[violations.length - 1]} />
            <div className="col m6">
              <ImageView packageId={violations[violations.length - 1].frontFacingImagePackageId}></ImageView>
              <ImageView packageId={violations[violations.length - 1].licensePlateImagePackageId}></ImageView>
            </div>
          </div>
        </div>
      </>
    );
  }
  return <span>No violations were associated to this potential violation</span>;
};

export const ConfirmedViolation = ({
  tenantId,
  event,
  authorizationHeader,
}: {
  tenantId: string;
  event: IConfirmedViolation;
  authorizationHeader: string;
}) => {
  const showFileContent = async (tenant: string, packageId: string): Promise<void> => {
    try {
      const { status, data } = await getPackageViewUrl(tenant, packageId, authorizationHeader);
      if (status !== 200) return;
      if (!data) {
        alert('File Not Found!');
        return;
      }
      window.open(data);
    } catch (error) {
      return;
    }
  };

  return (
    <div className="row">
      <div className="col s12 m12">
        <div className="card grey lighten-3">
          <div className="card-content black-text">
            <div className="row col s11 m11">
              <div className="urls-container">
                <p>
                  {event.certificateOfIntegrityPackageId ? (
                    <>
                      <FontAwesomeIcon icon={['far', 'file-pdf']} size="2x" style={{ marginRight: '0.1em', color: 'black' }} />
                      <strong>
                        <span
                          className="black-text"
                          onClick={() => showFileContent(tenantId, event.certificateOfIntegrityPackageId)}
                          style={{ marginRight: '1.5em', cursor: 'pointer' }}
                        >
                          Certificate of Integrity
                        </span>
                      </strong>
                    </>
                  ) : null}
                  {event.evidenceVideoPackageId ? (
                    <>
                      <FontAwesomeIcon icon={['far', 'file-video']} size="2x" style={{ marginRight: '0.1em', color: 'black' }} />
                      <strong>
                        <span
                          className="black-text"
                          onClick={() => showFileContent(tenantId, event.evidenceVideoPackageId)}
                          style={{ marginRight: '1.5em', cursor: 'pointer' }}
                        >
                          Evidence Video
                        </span>
                      </strong>
                    </>
                  ) : null}
                  {event.metadataPackageId ? (
                    <>
                      <FontAwesomeIcon icon={['far', 'file-code']} size="2x" style={{ marginRight: '0.1em', color: 'black' }} />
                      <strong>
                        <span
                          className="black-text"
                          onClick={() => showFileContent(tenantId, event.metadataPackageId)}
                          style={{ marginRight: '1.5em', cursor: 'pointer' }}
                        >
                          Metadata
                        </span>
                      </strong>
                    </>
                  ) : null}
                </p>
              </div>
            </div>
            <div className="col s1 m1">
              {!event.approved ? (
                <ApproveConfirmedViolation confirmedViolation={event} tenantId={tenantId} authorizationHeader={authorizationHeader} />
              ) : null}
            </div>
            <div className="row col s12">
              <span className="card-title">
                <strong>{event.permittedVehicle ? 'Permitted Vehicle' : 'Potential Violation'}</strong>
              </span>
            </div>
            <GetViolations violations={event.potentialViolations} />
          </div>
        </div>
      </div>
    </div>
  );
};
