import { buildQueryString } from './buildQueryString';

export const getConfirmedViolations = async (
  params: {
    page: number;
    createdDateTime: number;
    licensePlate: string;
    busNumber: string;
    startCaptureDateTime: number;
    endCaptureDateTime: number;
    detectionType: string;
    approved: boolean;
  },
  headersParams: { tenantId: string; authorizationHeader: string }
) => {
  const { page, createdDateTime, licensePlate, busNumber, startCaptureDateTime, endCaptureDateTime, detectionType, approved } = params;
  const { tenantId, authorizationHeader } = headersParams;
  const orderBy = '&orderList=-confirmedViolation.createdDateTime';
  const query = buildQueryString({ createdDateTime, licensePlate, busNumber, startCaptureDateTime, endCaptureDateTime, detectionType, approved });
  try {
    const host = window.location.origin;
    const pageSize = parseInt(process.env.REACT_APP_PAGE_SIZE as string);
    const response = await fetch(`${host}/api/confirmedViolations?page=${page}&pageSize=${pageSize}${orderBy}${query ? '&' + query : ''}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Tenant-Id': tenantId,
        Authorization: authorizationHeader,
      },
    });
    const body = await response.json();

    return { status: response.status, body };
  } catch (err) {
    throw new Error(`Error trying to get confirmed Violations ${err}`);
  }
};
