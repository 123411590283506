export const getPotentialViolations = async (tenantId: string, page: number,
  authorizationHeader: string, uploadTime?: number | null) => {
  const orderBy = '&orderList=-violation.createDateTime';
  const query = uploadTime ? `&query=violation.createDateTime:lte:${uploadTime}` : '';

  try {
    const host = window.location.origin;
    const pageSize = parseInt(process.env.REACT_APP_PAGE_SIZE as string);
    const response = await fetch(`${host}/api/potentialViolations?page=${page}&pageSize=${pageSize}${orderBy}${query}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Tenant-Id': tenantId,
        'Authorization': authorizationHeader
      },
    });
    const body = await response.json();

    return { status: response.status, body };
  } catch (err) {
    throw new Error(`Error trying to get potential violations ${err}`);
  }
};
